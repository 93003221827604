<template>
  <div>
    <div class="mt-2" style="background-color:white;padding:10px">
      <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>
      <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
        <h5>Edit Farm Details</h5>
        <div class="row">
          <div class="col-md-6">
            <b-form-group id="input-group-1" label="Name:" label-for="input-1">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Enter Project Name"
                  required
                ></b-form-input>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
            <b-form-group id="input-group-2" label="Code:" label-for="input-2">
              <ValidationProvider rules="alpha" v-slot="{ errors }">
                <b-form-input
                  id="input-2"
                  v-model="form.code"
                  placeholder="Enter Project Code"
                  required
                ></b-form-input>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Location:"
              label-for="input-2"
            >
              <ValidationProvider rules="alpha" v-slot="{ errors }">
                <b-form-input
                  id="input-2"
                  v-model="form.location"
                  placeholder="Enter the Address"
                  required
                ></b-form-input>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Google MAP Location URL:"
              label-for="input-2"
            >
              <ValidationProvider rules="alpha" v-slot="{ errors }">
                <b-form-input
                  id="input-2"
                  v-model="form.gmap_location"
                  placeholder="Enter Google MAP Location URL"
                  required
                ></b-form-input>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Description:"
              label-for="input-2"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-textarea
                  id="input-2"
                  v-model="form.description"
                  placeholder="Enter description..."
                  rows="5"
                  max-rows="8"
                ></b-form-textarea>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              id="input-group-2"
              label="Total area:"
              label-for="input-2"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-input
                  id="input-2"
                  type="number"
                  v-model="form.area"
                  placeholder="Enter Total area (In Acres)"
                  required
                ></b-form-input>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Select Organization :"
              label-for="input-2"
            >
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-select
                  v-model="form._organizationID"
                  value-field="_id"
                  text-field="name"
                >
                  <option value="">Select Organization</option>
                  <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="option._id"
                  >
                    {{ option.name }}
                  </option>
                </b-form-select>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
            <b-form-group id="input-group-2" label="Logo:" label-for="input-2">
              <b-form-file
                id="file"
                ref="file"
                v-model="form.image"
                :state="Boolean(form.logo)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @click="uploadImage"
              ></b-form-file>
            </b-form-group>
            <div class="row">
              <div class="col-md-6">
                <h5>Boundaries</h5>
              </div>
              <div class="col-md-6 mb-2 text-right">
                <button
                  class="btn btn-sm btn-primary"
                  type="button"
                  @click="addBoundary"
                >
                  New Boundary
                </button>
              </div>
              <div class="col-md-12">
                <table class="table table-sm">
                  <thead class="small thead-light">
                    <tr>
                      <th>#</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(boundary, index) in form.boundaries"
                      :key="index"
                    >
                      <td class="small align-middle">{{ index + 1 }}</td>
                      <td>
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            id="input-2"
                            v-model="boundary.latitude"
                            size="sm"
                            placeholder="Enter Latitude"
                            required
                          ></b-form-input>
                          <span class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                      <td>
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            id="input-2"
                            v-model="boundary.longitude"
                            size="sm"
                            placeholder="Enter Longitude"
                            required
                          ></b-form-input>
                          <span class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </td>
                      <td>
                        <button
                          class="btn btn-sm btn-danger"
                          type="button"
                          @click="removeBoundary(index)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <b-button size="sm" variant="success" @click="submitForm()">
              Update
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      farmDetails: "",
      form: {
        name: "",
        code: "",
        description: "",
        area: "",
        _organizationID: "",
        image: "",
        gmap_location: "",
        location: "",
        boundaries: [],
      },
      options: [],
      success: false,
    };
  },
  mounted() {
    this.getFarmDetails();
    this.getOrganization();
  },
  methods: {
    getFarmDetails() {
      let farmID = this.$route.params.id;
      window.axios.get("/project/" + farmID, {}).then((resp) => {
        console.log(resp.data.success);
        if (resp.data.success) {
          this.form = resp.data.data;
        }
      });
    },
    getOrganization() {
      window.axios.get("organization").then((resp) => {
        if (resp.data.success) {
          console.log(resp.data.data);
          this.options.push(resp.data.data);
        } else {
          alert(resp.data.message);
        }
      });
    },
    submitForm() {
      let farmID = this.$route.params.id;

      let formData = new FormData();
      formData.append("image", this.form.image);
      formData.append("code", this.form.code);
      formData.append("name", this.form.name);
      formData.append("description", this.form.description);
      formData.append("area", this.form.area);
      formData.append("gmap_location", this.form.gmap_location);
      formData.append("location", this.form.location);
      formData.append("_organizationID", this.form._organizationID);
      formData.append("boundaries", JSON.stringify(this.form.boundaries));
      window
        .axios({
          method: "put",
          url: "project/" + farmID,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.success) {
            this.success = resp.data.message;
            this.$emit("project-create", resp.data);
          }
          setTimeout(
            () => ((this.success = false), this.$router.push("/projects")),
            3000
          );
        });
    },
    addBoundary() {
      this.form.boundaries.push({
        latitude: "",
        longitude: "",
      });
    },
    removeBoundary(index) {
      this.form.boundaries.splice(index, 1);
    },
    uploadImage() {
      this.form.image = this.$refs.file.files[0];
    },
  },
};
</script>
