var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-2",staticStyle:{"background-color":"white","padding":"10px"}},[(_vm.success)?_c('b-alert',{attrs:{"variant":"success","show":""}},[_vm._v(" "+_vm._s(_vm.success))]):_vm._e(),_c('b-form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('h5',[_vm._v("Edit Farm Details")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Name:","label-for":"input-1"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-1","type":"text","placeholder":"Enter Project Name","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-2","label":"Code:","label-for":"input-2"}},[_c('ValidationProvider',{attrs:{"rules":"alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-2","placeholder":"Enter Project Code","required":""},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-2","label":"Location:","label-for":"input-2"}},[_c('ValidationProvider',{attrs:{"rules":"alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-2","placeholder":"Enter the Address","required":""},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-2","label":"Google MAP Location URL:","label-for":"input-2"}},[_c('ValidationProvider',{attrs:{"rules":"alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-2","placeholder":"Enter Google MAP Location URL","required":""},model:{value:(_vm.form.gmap_location),callback:function ($$v) {_vm.$set(_vm.form, "gmap_location", $$v)},expression:"form.gmap_location"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-2","label":"Description:","label-for":"input-2"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"input-2","placeholder":"Enter description...","rows":"5","max-rows":"8"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"input-group-2","label":"Total area:","label-for":"input-2"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-2","type":"number","placeholder":"Enter Total area (In Acres)","required":""},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-2","label":"Select Organization :","label-for":"input-2"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"value-field":"_id","text-field":"name"},model:{value:(_vm.form._organizationID),callback:function ($$v) {_vm.$set(_vm.form, "_organizationID", $$v)},expression:"form._organizationID"}},[_c('option',{attrs:{"value":""}},[_vm._v("Select Organization")]),_vm._l((_vm.options),function(option,index){return _c('option',{key:index,domProps:{"value":option._id}},[_vm._v(" "+_vm._s(option.name)+" ")])})],2),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-2","label":"Logo:","label-for":"input-2"}},[_c('b-form-file',{ref:"file",attrs:{"id":"file","state":Boolean(_vm.form.logo),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"click":_vm.uploadImage},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h5',[_vm._v("Boundaries")])]),_c('div',{staticClass:"col-md-6 mb-2 text-right"},[_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":_vm.addBoundary}},[_vm._v(" New Boundary ")])]),_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-sm"},[_c('thead',{staticClass:"small thead-light"},[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Latitude")]),_c('th',[_vm._v("Longitude")]),_c('th',[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.form.boundaries),function(boundary,index){return _c('tr',{key:index},[_c('td',{staticClass:"small align-middle"},[_vm._v(_vm._s(index + 1))]),_c('td',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-2","size":"sm","placeholder":"Enter Latitude","required":""},model:{value:(boundary.latitude),callback:function ($$v) {_vm.$set(boundary, "latitude", $$v)},expression:"boundary.latitude"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-2","size":"sm","placeholder":"Enter Longitude","required":""},model:{value:(boundary.longitude),callback:function ($$v) {_vm.$set(boundary, "longitude", $$v)},expression:"boundary.longitude"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('td',[_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeBoundary(index)}}},[_c('i',{staticClass:"fa fa-trash"})])])])}),0)])])])],1),_c('div',{staticClass:"col-md-12"},[_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Update ")])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }